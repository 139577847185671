.title {
  color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
  font-size: rem(100px);
  font-weight: 900;
  letter-spacing: rem(-2px);

  @media (max-width: $mantine-breakpoint-md) {
    font-size: rem(50px);
  }
}
