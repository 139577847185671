.Welcome_title__WEtp6 {
  color: var(--mantine-color-black);
}
  [data-mantine-color-scheme='dark'] .Welcome_title__WEtp6 {
  color: var(--mantine-color-white);
}
  .Welcome_title__WEtp6 {
  font-size: calc(6.25rem * var(--mantine-scale));
  font-weight: 900;
  letter-spacing: calc(-0.125rem * var(--mantine-scale));
}

  @media (max-width: 62em) {.Welcome_title__WEtp6 {
    font-size: calc(3.125rem * var(--mantine-scale))
}
  }

